import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-152f17d6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = {
  key: 0,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_3 = {
  key: 1,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_4 = {
  key: 2,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_5 = {
  key: 3,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_6 = {
  key: 4,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_7 = {
  key: 5,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_8 = {
  key: 6,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_9 = {
  key: 7,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_10 = {
  key: 8,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_11 = {
  key: 9,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_12 = {
  key: 10,
  class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3"
}
const _hoisted_13 = { class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3" }
const _hoisted_14 = { class: "col-lg-4 col-md-4 col-sm-4 col-xs-6 no-border mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.proposal.proposalItems, (items, index) => {
    return (_openBlock(), _createElementBlock("div", {
      class: "card border-0 pr-3 font-size-12 h-100",
      key: index
    }, [
      _createElementVNode("div", _hoisted_1, [
        (items.financingProductPack?.resourceUid)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.financingProduct',
                  value: `${items.financingProduct.replace('PROFILE.', '')}`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.financedValue)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.financedValue',
                  value: `${_ctx.formatAmoutswitchCurrency('EUR', items.financedValue)} `
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.financialPaymentWoTax)
          ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.financialPaymentWoTax',
                  value: `${_ctx.formatAmoutswitchCurrency('EUR', items.financialPaymentWoTax)}`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.financialPaymentWTax)
          ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.financialPaymentWTax',
                  value: `${_ctx.formatAmoutswitchCurrency('EUR', items.financialPaymentWTax)}`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.numberOfPaymentTerm)
          ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.numberOfPaymentTerm',
                  value: `${items.numberOfPaymentTerm} ${_ctx.$t(
              `middleOfficeFinancingForm.month`
            )}`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.nominalRate)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.nominalRate',
                  value: `${_ctx.formatWithMask(items.nominalRate, 2)} %`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.financingProduct?.replace('PROFILE.', '')==='RACC')
          ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
              _createVNode(_component_LabelAndValue, {
                i18nKey: 'middleOfficeFinancingForm.label.downPayment',
                value: `${_ctx.formatAmoutswitchCurrency('EUR', items.downPayment)}`
              }, null, 8, ["i18nKey", "value"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_9, [
              _createVNode(_component_LabelAndValue, {
                i18nKey: 'middleOfficeFinancingForm.label.firstPaymentWTax',
                value: `${_ctx.formatAmoutswitchCurrency('EUR', items.firstPaymentWTax)}`
              }, null, 8, ["i18nKey", "value"])
            ])),
        (items.downPayment)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_LabelAndValue, {
                i18nKey: 'middleOfficeFinancingForm.label.downPayment',
                value: `${_ctx.formatAmoutswitchCurrency('EUR', items.downPayment!)}`
              }, null, 8, ["i18nKey", "value"])
            ]))
          : _createCommentVNode("", true),
        (items.mileage)
          ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.mileage',
                  value: `${items.mileage} km`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        (items.residualValueAmountWTax)
          ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
              _createElementVNode("div", null, [
                _createVNode(_component_LabelAndValue, {
                  i18nKey: 'middleOfficeFinancingForm.label.residualValueAmount',
                  value: `${_ctx.formatAmoutswitchCurrency('EUR', items.residualValueAmountWTax)} || ${_ctx.formatWithMask(items.residualValuePercentage, 2)} %`
                }, null, 8, ["i18nKey", "value"])
              ])
            ]))
          : _createCommentVNode("", true),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", null, [
            _createVNode(_component_LabelAndValue, {
              i18nKey: 'middleOfficeFinancingForm.label.totalAmountWInsuranceTTC',
              value: `${_ctx.formatAmoutswitchCurrency('EUR', _ctx.priceWithTax(items))}`
            }, null, 8, ["i18nKey", "value"])
          ])
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("div", null, [
            _createVNode(_component_LabelAndValue, {
              i18nKey: 'middleOfficeFinancingForm.label.totalAmountWoInsuranceTTC',
              value: `${_ctx.formatAmoutswitchCurrency('EUR', _ctx.priceWithoutTax(items))}`
            }, null, 8, ["i18nKey", "value"])
          ])
        ])
      ])
    ]))
  }), 128))
}