
import { Vue, Options } from "vue-class-component";
import { businessDataUtils, Proposal, proposalAccessoriesObjectTypes } from "@/types";
import { formatMask, formatAmoutswitchCurrency } from "@/utils/configuration/formatters-config";
import store from "@/store";
import FinancingPartyInsurance from "@/components/middle-office/tabs/financing/FinancingPartyInsurance.vue";
import { serviceName, amountHT, amountTTC, paymentDatesOption} from "@/utils";
@Options({
  props: ["proposal"],
  components: { FinancingPartyInsurance }
})
export default class FinancingServices extends Vue {
  serviceName = serviceName;
  amountHT = amountHT;
  amountTTC = amountTTC;
  paymentDatesOption = paymentDatesOption;
  proposalPartyInsuranceObjectType = proposalAccessoriesObjectTypes.proposalPartyInsuranceObjectType
  proposalsimpleservice = proposalAccessoriesObjectTypes.proposalsimpleservice
  simpleServiceProductItem = proposalAccessoriesObjectTypes.simpleServiceProductItem
  proposalCommission = proposalAccessoriesObjectTypes.proposalCommission

  proposal?: Proposal;
  money = formatMask;
  get calculatestatus() {
    return config.VUE_APP_CALCULATE
  }
  get partyInsurance() {
  let accessories;

  if (
    config.VUE_APP_CALCULATE === 'false' &&
    this.offer?.businessData &&
    this.offer?.businessData.API === businessDataUtils.offerApiSource
  ) {
    accessories =
      this.proposal?.proposalItems[0].proposalAccessories.filter(
        (accessorie: any) =>
          accessorie.objectType.includes(this.proposalPartyInsuranceObjectType)
      ) || [];
  } else {
    accessories =
      store.state.middleOfficeModule?.proposalScale?.scalePresentation
        ?.servicePartyInsurance || [];
  }

  const consolidatedAccessories = accessories.reduce((acc: any[], current: any) => {
    const existingAccessory = acc.find(
      (item) =>
        item.accessoryProduct?.resourceUid === current.accessoryProduct?.resourceUid
    );

    if (existingAccessory) {
      if (current.associatedInsuranceBeneficiaries?.length) {
        existingAccessory.associatedInsuranceBeneficiaries = [
          ...(existingAccessory.associatedInsuranceBeneficiaries || []),
          ...current.associatedInsuranceBeneficiaries,
        ];
      }
    } else {
      acc.push({
        ...current,
        associatedInsuranceBeneficiaries:
          current.associatedInsuranceBeneficiaries || [],
      });
    }

    return acc;
  }, []);

  return consolidatedAccessories;
}


  get offer() {
    return store.state.middleOfficeModule?.offer
  }

  get servicesList() {
    let services;
    if (config.VUE_APP_CALCULATE === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource)) {
      services = this.proposal?.proposalItems[0].proposalAccessories.filter((accessorie: any) => accessorie.objectType.includes(this.proposalsimpleservice) 
      || accessorie.objectType.includes(this.proposalCommission)) || []
    }

    else {
      services = store.state.middleOfficeModule?.proposalScale?.scalePresentation?.services.filter((accessorie: any) => accessorie.objectType.includes(this.simpleServiceProductItem)) || []
    }
    return services ;
  }

 
  get totalAmount() {
    const Items = this.proposal?.proposalItems;
    if (Items) {
      let totalAmount = 0;
      this.proposal?.proposalItems.forEach((item: any) => {
        totalAmount = totalAmount + item.financialPaymentWTax;
      });

      return formatAmoutswitchCurrency('EUR', totalAmount);
    }
    return 0;
  }

  get totalAmountHs() {
    const Items = this.proposal?.proposalItems;
    if (Items) {
      let totalAmountHs = 0;
      this.proposal?.proposalItems.forEach((item: any) => {
        totalAmountHs = totalAmountHs + item.financialPaymentWoTax;
      });

      return formatAmoutswitchCurrency('EUR', totalAmountHs);
    }
    return "0";
  }

  get numberOfPaymentTerm() {
    const Items = this.proposal?.proposalItems;
    if (Items) {
      let numberOfPaymentTerm = 0;
      this.proposal?.proposalItems.forEach((item: any) => {
        if (item.numberOfPaymentTerm > numberOfPaymentTerm)
          numberOfPaymentTerm = item.numberOfPaymentTerm;
      });

      return numberOfPaymentTerm;
    }
    return 0;
  }

  openServicesDetail() {
    store.dispatch("middleOfficeModule/showService", { showService: true });
  }
}
