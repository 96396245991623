import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row mb-2" }
const _hoisted_2 = { class: "col-6 mb-2" }
const _hoisted_3 = { class: "col-6 mb-2" }
const _hoisted_4 = { class: "row mb-2" }
const _hoisted_5 = { class: "col-6 mb-2" }
const _hoisted_6 = { class: "col-6 mb-2" }
const _hoisted_7 = { class: "col-6 mb-2" }
const _hoisted_8 = { class: "row mb-2" }
const _hoisted_9 = { class: "col-6 mb-2" }
const _hoisted_10 = { class: "col-6 mb-2" }
const _hoisted_11 = { class: "row" }
const _hoisted_12 = { class: "col-4 mb-2" }
const _hoisted_13 = { class: "col-4 mb-2" }
const _hoisted_14 = { class: "col-4 mb-2" }
const _hoisted_15 = { class: "col-4 mb-2" }
const _hoisted_16 = { key: 1 }
const _hoisted_17 = { class: "mb-3 pl-3 pr-3" }
const _hoisted_18 = { class: "same-line" }
const _hoisted_19 = { class: "text-dark font-weight-bold" }
const _hoisted_20 = { class: "shadow-0 table table-sm mb-0" }
const _hoisted_21 = { class: "text-dark font-weight-bold" }
const _hoisted_22 = { class: "text-dark font-weight-bold" }
const _hoisted_23 = { class: "text-dark font-weight-bold" }
const _hoisted_24 = { class: "text-dark font-weight-bold" }
const _hoisted_25 = { class: "same-line" }
const _hoisted_26 = { class: "text-dark font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LabelAndValue = _resolveComponent("LabelAndValue")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.organization.corporateName',
          value: _ctx.organization.name,
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.organization.commercialName',
          value: _ctx.organization.commercialName,
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.organization.siren',
          value: _ctx.organization.siren,
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.organization.legalCategory',
          value: _ctx.legalCategory || '--',
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.organization.activity',
          value: 
  _ctx.$t(`picklists.activity.${_ctx.organization.activitySector.type.value}`)
        ,
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.organization.phoneNumber',
          value: _ctx.contacts?.get(_ctx.contactType?.PHONE_CONTACT) ?? '--',
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createVNode(_component_LabelAndValue, {
          "i18n-key": 'summaryPanel.organization.email',
          value: _ctx.contacts?.get(_ctx.contactType?.EMAIL_CONTACT) ?? '--',
          oneLine: true,
          "value-style": _ctx.valueStyle,
          "label-style": _ctx.labelStyleItem,
          "div-value-style": _ctx.divValueStyle
        }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
      ])
    ]),
    (_ctx.organization.manager)
      ? (_openBlock(), _createBlock(_component_q_expansion_item, {
          key: 0,
          "default-opened": "",
          "expand-icon-toggle": "",
          "header-class": "align-items-center bg-secondary justify-content-between mb-3 mt-3"
        }, {
          header: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.capitalize(_ctx.$t("demand.party.roles.DIRG").toLowerCase())), 1)
          ]),
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_11, [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_LabelAndValue, {
                  "i18n-key": 'summaryPanel.organization.title',
                  value: _ctx.$t(`demand.party.title.${_ctx.organization.manager.title.type.value}`),
                  oneLine: true,
                  "value-style": _ctx.valueStyle,
                  "label-style": _ctx.labelStyleItem,
                  "div-value-style": _ctx.divValueStyle
                }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
              ]),
              _createElementVNode("div", _hoisted_13, [
                _createVNode(_component_LabelAndValue, {
                  "i18n-key": 'summaryPanel.organization.fullName',
                  value: (_ctx.organization.manager.firstName && _ctx.organization.manager.familyName) ? (_ctx.organization.manager.firstName + ' ' + _ctx.organization.manager.familyName) : '--',
                  oneLine: true,
                  "value-style": _ctx.valueStyle,
                  "label-style": _ctx.labelStyleItem,
                  "div-value-style": _ctx.divValueStyle
                }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
              ]),
              _createElementVNode("div", _hoisted_14, [
                _createVNode(_component_LabelAndValue, {
                  "i18n-key": 'summaryPanel.organization.birthday',
                  value: _ctx.organization.manager.birthDate || '--',
                  oneLine: true,
                  "value-style": _ctx.valueStyle,
                  "label-style": _ctx.labelStyleItem,
                  "div-value-style": _ctx.divValueStyle
                }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createVNode(_component_LabelAndValue, {
                  "i18n-key": 'summaryPanel.organization.phoneNumber',
                  value: _ctx.managerPhoneNumber || '--',
                  oneLine: true,
                  "value-style": _ctx.valueStyle,
                  "label-style": _ctx.labelStyleItem,
                  "div-value-style": _ctx.divValueStyle
                }, null, 8, ["i18n-key", "value", "value-style", "label-style", "div-value-style"])
              ])
            ])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (!_ctx.order ||(_ctx.order!=='order'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
          (_ctx.rows && _ctx.rows.length != 0)
            ? (_openBlock(), _createBlock(_component_q_expansion_item, {
                key: 0,
                "default-opened": "",
                "expand-icon-toggle": "",
                "header-class": "align-items-center justify-content-between mb-3 mt-3",
                class: "bg-secondary"
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(_ctx.capitalize(
            _ctx.$t("middleOffice.party.organization.section.classification").toLowerCase()
          )), 1)
                ]),
                default: _withCtx(() => [
                  _createElementVNode("div", _hoisted_17, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (item) => {
                      return (_openBlock(), _createElementBlock("div", {
                        key: item.id
                      }, [
                        _createElementVNode("div", _hoisted_18, [
                          _createElementVNode("span", null, _toDisplayString(_ctx.$t('main.type')) + ": ", 1),
                          _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.$t("picklists.partyClassificationType." + item.class_type_code)), 1)
                        ]),
                        _createElementVNode("table", _hoisted_20, [
                          _createElementVNode("thead", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("th", _hoisted_21, _toDisplayString(_ctx.$t("task.labels.value")), 1),
                              _createElementVNode("th", _hoisted_22, _toDisplayString(_ctx.$t("communication.comMessage.User")), 1),
                              _createElementVNode("th", _hoisted_23, _toDisplayString(_ctx.$t("scales.services.filter.validity")), 1),
                              _createElementVNode("th", _hoisted_24, _toDisplayString(_ctx.$t("main.labels.classificationDate")), 1)
                            ])
                          ]),
                          _createElementVNode("tbody", null, [
                            _createElementVNode("tr", null, [
                              _createElementVNode("td", null, _toDisplayString(_ctx.$t(`demand.party.partyClass.${item.id}`)), 1),
                              _createElementVNode("td", null, _toDisplayString(item.first_name ? item.first_name + ' ' + item.last_name : item._who_created), 1),
                              _createElementVNode("td", null, _toDisplayString(`${item.validity_from ? _ctx.formatDate(item.validity_from) : ''} - ${item.validity_until ? _ctx.formatDate(item.validity_until) : ''}`), 1),
                              _createElementVNode("td", null, _toDisplayString(_ctx.formatDate(item.classification_date)), 1)
                            ])
                          ])
                        ])
                      ]))
                    }), 128))
                  ])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    (_ctx.financialExposureTable && _ctx.financialExposureTable.length != 0)
      ? (_openBlock(), _createBlock(_component_q_expansion_item, {
          key: 2,
          "default-opened": "",
          "expand-icon-toggle": "",
          "header-class": "align-items-center justify-content-between mb-3 mt-3",
          class: "bg-secondary"
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", null, _toDisplayString(_ctx.capitalize(
            _ctx.$t("middleOffice.party.organization.section.exposure").toLowerCase()
          )), 1)
          ]),
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.financialExposureTable, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "mb-3 pl-3 pr-3"
              }, [
                _createElementVNode("div", _hoisted_25, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.$t('middleOffice.party.organization.exposure.Outstanding_entity')) + ": ", 1),
                  _createElementVNode("span", _hoisted_26, _toDisplayString(item.financialInstitution || ''), 1)
                ]),
                _createVNode(_component_q_table, {
                  rows: item.outstandingTypes,
                  columns: _ctx.columns,
                  "row-key": "financialInstitution",
                  "hide-pagination": "",
                  class: "shadow-0 table table-sm mb-0"
                }, {
                  header: _withCtx((props) => [
                    _createVNode(_component_q_tr, { props: props }, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                          return (_openBlock(), _createBlock(_component_q_th, {
                            key: col.name,
                            props: props,
                            class: "text-dark font-weight-bold"
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                            ]),
                            _: 2
                          }, 1032, ["props"]))
                        }), 128)),
                        _createVNode(_component_q_th, { "auto-width": "" })
                      ]),
                      _: 2
                    }, 1032, ["props"])
                  ]),
                  _: 2
                }, 1032, ["rows", "columns"])
              ]))
            }), 128))
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
  ], 64))
}