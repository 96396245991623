
import { Vue, Options } from "vue-class-component";
import {businessDataUtils, Proposal} from "@/types";
import { formatWithMask, formatAmoutswitchCurrency } from "@/utils/configuration/formatters-config";
import store from "@/store";

@Options({
  props: ["proposal"],
})
export default class FinancingSituation extends Vue {

  proposal?: Proposal;
  formatWithMask = formatWithMask;
  formatAmoutswitchCurrency = formatAmoutswitchCurrency;

  get air() {
    let air = 0;
    this.proposal?.proposalItems.forEach((item) => {
      air = item.air || 0;
    });
    return air;
  }

  get aprc() {
    let aprc = 0;
    this.proposal?.proposalItems.forEach((item) => {
      aprc = item.aprc || 0;
    });
    return aprc;
  }

  get sumOfTotalPayments() {
    let sumOfTotalPayments = 0;
    if (config.VUE_APP_CALCULATE === 'false' && this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) {
      this.proposal?.proposalItems.forEach((item: any) => {
        sumOfTotalPayments = item.sumOfTotalPayments || 0;
      });
      return sumOfTotalPayments;
    }
  }

  get offer() {
    return store.state.taskModule.offer || store.state.middleOfficeModule.offer;
  }
  get sumOfInterests() {
    let sumOfInterests = 0;
    if (config.VUE_APP_CALCULATE === 'false' && this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) {
      this.proposal?.proposalItems.forEach((item: any) => {
        sumOfInterests = item.sumOfInterests || 0;
      });
      return sumOfInterests;
    } else {
      return store.getters['middleOfficeModule/getSumOfInterests'];
    }
  }
}
